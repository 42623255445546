<template>
  <div class="privacy-policy">
    <!-- App Title as Logo -->
    <h3 class="app-title">
      <span class="underline">Perfume</span> Prophet.
    </h3>

    <!-- Privacy Policy Header -->
    <h1 class="policy-header">Privacy Policy</h1>
    <p class="last-updated">Last updated: November 1, 2024</p>

    <!-- Privacy Sections with Breaks -->
    <section>
      <h3>1. Information Collection</h3>
      <p>We collect certain information when you use our app to provide a better user experience. The types of data we collect include:</p>
      <ul>
        <li>Personal information you provide directly, such as your name, email address, and other details if applicable.</li>
        <li>Usage data, including interactions with the app and features used, to understand and improve app performance.</li>
      </ul>
    </section>
    <hr />

    <section>
      <h3>2. Use of Information</h3>
      <p>We use the collected data to:</p>
      <ul>
        <li>Provide and maintain the app’s functionality.</li>
        <li>Analyze app usage to improve performance and user experience.</li>
        <li>Communicate with you about updates or support when necessary.</li>
      </ul>
    </section>
    <hr />

    <section>
      <h3>3. Data Sharing and Disclosure</h3>
      <p>We do not sell or share your personal data with third parties except in the following cases:</p>
      <ul>
        <li>To service providers who assist in the app's operations, with strict confidentiality agreements.</li>
        <li>When required by law or to respond to legal requests or protect the rights, property, or safety of our app, users, or others.</li>
      </ul>
    </section>
    <hr />

    <section>
      <h3>4. Cookie Usage and Tracking</h3>
      <p>Our app uses cookies for Google Analytics tracking in the web version only:</p>
      <ul>
        <li>When you access our app through a web browser, you may be presented with a cookie consent banner.</li>
        <li>If you accept cookies, we use Google Analytics to track usage and improve our app's performance. If you decline, we do not track usage through Google Analytics.</li>
        <li><strong>Note:</strong> In the iOS app, we do not display the cookie consent banner, and Google Analytics is not used or initialized. No cookies are stored in the iOS version of the app.</li>
      </ul>
    </section>
    <hr />

    <section>
      <h3>5. User Rights</h3>
      <p>You have the right to:</p>
      <ul>
        <li>Access the data we hold about you.</li>
        <li>Request corrections to inaccurate or incomplete information.</li>
        <li>Request deletion of your personal data, subject to legal or contractual obligations.</li>
      </ul>
      <p>To exercise these rights, please contact us at the information provided below.</p>
    </section>
    <hr />

    <section>
      <h3>6. Security</h3>
      <p>We take the security of your data seriously and implement industry-standard measures to protect it. However, please note that no method of electronic storage is 100% secure.</p>
    </section>
    <hr />

    <section>
      <h3>7. Contact Information</h3>
      <p>If you have any questions about this Privacy Policy or wish to make a request regarding your data, please contact us at:</p>
      <p>Email: mail@perfumeprophet.com</p>
    </section>
    <hr />

    <section>
      <h3>8. Updates to the Policy</h3>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Please review this policy periodically for any updates.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  color: #333;
}

/* App Title Styling */
.app-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin-bottom: 10px;
}

.underline {
  text-decoration: underline;
}

/* Privacy Policy Header */
.policy-header {
  font-size: 1.5rem;
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.last-updated {
  text-align: center;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
}

h3 {
  color: #333;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

p {
  color: #555;
}

/* Add a styled horizontal rule */
hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}
</style>
