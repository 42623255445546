import Vue from 'vue';
import Router from 'vue-router';
import StartPage from '@/components/StartPage.vue';
import PerfumeDataTable from '@/components/PerfumeDataTable.vue';
import ExplorePage from '@/components/ExplorePage.vue';
import ExploreAllPage from '@/components/ExploreAllPage.vue';
import { Capacitor } from '@capacitor/core';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'StartPage',
      component: StartPage,
    },
    {
      path: '/explore',
      name: 'ExplorePage',
      component: ExplorePage,
    },
    {
      path: '/explore_all',
      name: 'ExploreAllPage',
      component: ExploreAllPage,
    },
    {
      path: '/perfumes',
      name: 'PerfumeDataTable',
      component: PerfumeDataTable,
    },
    {
      path: '/favorites',
      name: 'FavoritesList',
      component: () => import('@/components/FavoritesList.vue'),
    },
    {
      path: '/app-privacy', // Add Privacy Policy route
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.path === '/perfumes' || to.path === '/favorites') {
      return { x: 0, y: 0 }; // Always scroll to top for /perfumes or /favorites
    }
    if (savedPosition) {
      return savedPosition; // Return to saved position if available (used when navigating back)
    }
    return { x: 0, y: 0 };
  },
});

// Navigation guard to block StartPage navigation on iOS
router.beforeEach((to, from, next) => {
  const isIOSApp = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';

  // Prevent navigation back to StartPage on iOS app if not directly accessing StartPage
  if (isIOSApp && to.path === '/' && from.path !== '/') {
    next(false); // Block navigation
  } else {
    next(); // Allow navigation
  }
});

export default router;
